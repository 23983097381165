<script setup>
    import { inject, computed, ref, onMounted } from 'vue'
    import { store } from '../store/store'

    // Primevue
    import Button from 'primevue/button'
    import Image from 'primevue/image'
    import Toolbar from 'primevue/toolbar'

    const props = defineProps({
        companyLogo: String,
        mobileApp: Boolean,
    })

    const $cookies = inject('$cookies')
    const cookieTheme = ref('')

    const showToggle = computed(() => {
        return cookieTheme !== 'mobileApp'
    })

    const cycleTheme = () => {
        // device --> light --> dark
        const newTheme =
            cookieTheme.value === 'device'
                ? 'light'
                : cookieTheme.value === 'light'
                    ? 'dark'
                    : 'device'

        const root = document.getElementsByTagName('html')[0]

        switch (newTheme) {
            case 'light':
                // Cycling from device to light
                store.changeTheme('light')
                cookieTheme.value = 'light'
                break
            case 'dark':
                store.changeTheme('dark')
                cookieTheme.value = 'dark'
                break
            default:
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    store.changeTheme('dark')
                } else {
                    store.changeTheme('light')
                }
                cookieTheme.value = 'device'
                break
        }
        if (store.currentTheme === 'light') {
            if (root.classList.contains(store.darkThemeClass)) {
                root.classList.remove(store.darkThemeClass)
                root.classList.add(store.lightThemeClass)
            }
        } else {
            if (root.classList.contains(store.lightThemeClass)) {
                root.classList.remove(store.lightThemeClass)
                root.classList.add(store.darkThemeClass)
            }
        }

        if ($cookies) {
            $cookies.set('theme', cookieTheme.value, -1, undefined, undefined, undefined, 'Strict')
        }
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        if (cookieTheme.value === 'device') {
            if (e.matches) {
                store.changeTheme('dark')
            } else {
                store.changeTheme('light')
            }
            const root = document.getElementsByTagName('html')[0]
            if (store.currentTheme === 'light') {
                if (root.classList.contains(store.darkThemeClass)) {
                    root.classList.remove(store.darkThemeClass)
                    root.classList.add(store.lightThemeClass)
                }
            } else {
                if (root.classList.contains(store.lightThemeClass)) {
                    root.classList.remove(store.lightThemeClass)
                    root.classList.add(store.darkThemeClass)
                }
            }
        }
    })

    const appearanceText = computed(() => {
        return cookieTheme.value === 'light'
            ? 'Using light theme. Click to change to dark theme'
            : cookieTheme.value === 'dark'
                ? 'Using dark theme. Click to change to device theme'
                : 'Using device theme. Click to change to light theme'
    })

    const iconClass = computed(() => {
        return cookieTheme.value === 'light'
            ? 'fa-brightness-low'
            : cookieTheme.value === 'dark'
                ? 'fa-moon-stars'
                : 'fa-mobile'
    })

    onMounted(() => {
        if (props.mobileApp) {
            if ($cookies) {
                $cookies.set('theme', 'mobileApp', -1, undefined, undefined, undefined, 'Strict')
            }
        }
        cookieTheme.value = $cookies?.get('theme') ?? 'light'
        store.changeTheme(cookieTheme.value)
        const root = document.getElementsByTagName('html')[0]
        root.classList.add(store.brandThemeClass)
    })


</script>
<template>
  <header>
    <Toolbar>
      <template #start>
          <div class="w-[21px]"></div>
      </template>
      <template #center>
        <Image :src="companyLogo" alt="Company Logo"
          :pt="{ image: { class: 'h-16 border border-solid border-background rounded-xl' }, root: { class: 'flex justify-center' } }" />
      </template>
      <template #end>
        <Button v-tooltip.left="appearanceText" v-if="showToggle" @click="cycleTheme" :aria-label="appearanceText" severity="help"
          class="'flex w-full'"  
          :icon="`fa-regular fa-xl lg:fa-2xl mr-0 ${iconClass}`"></Button>
      </template>
    </Toolbar>
  </header>
</template>