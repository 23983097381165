﻿/** 
 * PrimeVue configuration
 */

// MentorNet Design with Tailwind
const MentorNetDesign = {

    global: {
        css: `
        .progress-spinner-circle {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: 0;
            stroke: hsl(var(--twc-primary));
            animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
            stroke-linecap: round;
        }

        @keyframes p-progress-spinner-dash{
            0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
            }
            
            50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35px;
            }
            100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124px;
            }
        }
    `
    },
    button: {
        root: ({ props, context }) => ({
            class: [
                'items-center justify-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom max-h-10',
                'transition duration-200 ease-in-out',
                'focus:ring-2 focus:ring-offset-2 hover:ring-2 hover:ring-offset-2 outline-none',
                {
                    'text-textOnPrimary bg-primary border border-primary ring-primary ring-offset-secondary':
                        !props.link &&
                        props.severity === null &&
                        !props.text &&
                        !props.outlined &&
                        !props.plain,
                    'text-textOnBackground bg-transparent border-transparent ring-primary':
                        props.link
                },
                {
                    'text-textOnSecondary bg-secondary border border-secondary ring-secondary ring-offset-primary':
                        props.severity === 'secondary' &&
                        !props.text &&
                        !props.outlined &&
                        !props.plain
                },
                {
                    'text-textOnBackground bg-backgroundOffset2 border border-backgroundOffset2 ring-backgroundOffset2 ring-offset-primary':
                        props.severity === 'contrast' &&
                        !props.text &&
                        !props.outlined &&
                        !props.plain
                },
                {
                    'text-textOnBackground bg-transparent text-sm px-0 py-1':
                        props.severity === 'help'
                },
                {
                    'shadow-md shadow-textOnBackgroundDisabled/25': props.raised
                },
                {
                    'rounded-xl': !props.rounded,
                    'rounded-full': props.rounded
                },
                {
                    'bg-transparent border-transparent':
                        props.text && !props.plain,
                    'text-textOnPrimary ':
                        props.text &&
                        props.severity === 'secondary' &&
                        !props.plain
                },
                {
                    'shadow-lg shadow-textOnBackgroundDisabled/25':
                        props.raised && props.text
                },
                {
                    'text-textOnPrimary ':
                        props.plain && props.text,
                    'text-textPrimary border border-primary':
                        props.plain && props.outlined,
                    'text-textOnPrimary bg-secondary border border-secondary hover:bg-secondary hover:border-secondary':
                        props.plain &&
                        !props.outlined &&
                        !props.text
                },
                {
                    'bg-transparent border':
                        props.outlined && !props.plain,
                    'text-primary border border-primary hover:bg-primary':
                        props.outlined &&
                        (props.severity === null ||
                            props.severity === 'info') &&
                        !props.plain,
                    'text-textOnPrimary border border-secondary hover:bg-secondary':
                        props.outlined &&
                        props.severity === 'secondary' &&
                        !props.plain
                },
                {
                    'px-4 py-3 text-base':
                        props.size === null &&
                        props.severity !== 'help',
                    'text-xs py-2 px-3':
                        props.size === 'small' &&
                        props.severity !== 'help',
                    'text-xl py-3 px-4':
                        props.size === 'large' &&
                        props.severity !== 'help'
                },
                {
                    'flex-column':
                        props.iconPos == 'top' ||
                        props.iconPos == 'bottom'
                },
                {
                    'opacity-60 pointer-events-none cursor-default':
                        context.disabled
                }
            ]
        }),
        label: ({ props }) => ({
            class: [
                'flex-1',
                'duration-200',
                'font-medium',
                {
                    'hover:underline': props.link
                },
                { 'invisible w-0': props.label == null }
            ]
        }),
        icon: ({ props }) => ({
            class: [
                'mx-0',
                {
                    'mr-2':
                        props.iconPos == 'left' &&
                        props.label != null,
                    'ml-2 order-1':
                        props.iconPos == 'right' &&
                        props.label != null,
                    'mb-2':
                        props.iconPos == 'top' &&
                        props.label != null,
                    'mt-2 order-2':
                        props.iconPos == 'bottom' &&
                        props.label != null
                },
                {
                    'text-error': props.severity === 'error'
                }
            ]
        }),
        pcBadge: ({ props }) => ({
            root: {
                class: [
                    {
                        'ml-2 w-4 h-4 leading-none flex items-center justify-center':
                            props.badge
                    }
                ]
            }
        })
    },
    card: {
        root: {
            class: [
                'bg-background text-textOnBackground border-2 border-backgroundOffset3 shadow-none lg:border lg:border-border lg:shadow-card lg:shadow-shadow rounded-md p-3' // Background, text color, box shadow, and border radius.
            ]
        },
        content: 'py-1', // Padding.
        header: 'font-medium text-base lg:text-lg mb-1', // Font size, font weight, and margin bottom.
        title: 'font-semibold text-lg lg:text-xl mb-1', // Font size, font weight, and margin bottom.
        subtitle: {
            class: [
                'font-normal text-sm lg:base mb-2' // Font weight, margin bottom, and text color.
            ]
        },
        footer: 'pt-5' // Top padding.
    },
    checkbox: {
        root: {
            class: ['cursor-pointer inline-flex relative select-none align-bottom', 'w-6 h-6']
        },
        box: {
            class: [
                // Alignment
                'flex',
                'items-center',
                'justify-center',
                // Size
                'w-6',
                'h-6',
                // Shape
                'rounded-md',
                'border-2',
                'border-textOnSecondary'
            ]
        },
        input: ({ props }) => ({
            class: [
                'peer',
                'w-full ',
                'h-full',
                'absolute',
                'top-0 left-0',
                'z-10',
                'p-0',
                'm-0',
                'rounded-md',
                'outline-none',
                'border-2 border-textOnSecondary',
                'appearance-none',
                'cursor-pointer',
                // States
                {
                    'hover:ring-primary hover:ring-offset-2 hover:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-2':
                        !props.disabled,
                    'cursor-default opacity-60 bg-backgroundOffset3':
                        props.disabled
                }
            ]
        }),
        icon: {
            class: [
                'text-textOnSecondary leading-none',
                'w-4',
                'h-4',
                'transition-all',
                'duration-200'
            ]
        }
    },
    dialog: {
        root: ({ state }) => ({
            class: [
                'rounded-lg shadow-shadow border border-border',
                'max-h-[90%] max-w-[90%] transform scale-100',
                'm-0',
                {
                    'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
                        state.maximized
                }
            ]
        }),
        header: {
            class: [
                'flex items-center justify-between gap-4 shrink-0',
                'bg-primary text-textOnPrimary border-t-0 rounded-tl-lg rounded-tr-lg p-3'
            ]
        },
        title: {
            class: 'font-normal text-base'
        },
        headerActions: {
            class: 'flex items-center justify-center'
        },
        pcCloseButton: {
            root: {
                class: [
                    'flex items-center justify-center overflow-hidden relative',
                    'text-textOnPrimary border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0 px-2'
                ]
            }
        },
        content: ({ instance, state }) => ({
            class: [
                'overflow-y-auto',
                'bg-background text-textOnBackground text-sm px-6 pb-8 pt-0',
                {
                    grow: state.maximized
                },
                {
                    'rounded-bl-lg rounded-br-lg': !instance.$slots.footer
                }
            ]
        }),
        footer: {
            class: [
                'flex gap-2 shrink-0 justify-end align-center',
                'border-t-0 bg-background text-textOnBackground px-6 pb-6 text-right rounded-b-lg'
            ]
        },
        mask: ({ props }) => ({
            class: ['transition duration-200', { 'bg-black/40': props.modal }]
        }),
        transition: ({ props }) => {
            return props.position === 'top'
                ? {
                    enterFromClass:
                        'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                    enterActiveClass: 'transition-all duration-200 ease-out',
                    leaveActiveClass: 'transition-all duration-200 ease-out',
                    leaveToClass:
                        'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
                }
                : props.position === 'bottom'
                    ? {
                        enterFromClass: 'opacity-0 scale-75 translate-y-full',
                        enterActiveClass: 'transition-all duration-200 ease-out',
                        leaveActiveClass: 'transition-all duration-200 ease-out',
                        leaveToClass:
                            'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
                    }
                    : props.position === 'left' ||
                        props.position === 'topleft' ||
                        props.position === 'bottomleft'
                        ? {
                            enterFromClass:
                                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                            enterActiveClass: 'transition-all duration-200 ease-out',
                            leaveActiveClass: 'transition-all duration-200 ease-out',
                            leaveToClass:
                                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
                        }
                        : props.position === 'right' ||
                            props.position === 'topright' ||
                            props.position === 'bottomright'
                            ? {
                                enterFromClass:
                                    'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass:
                                    'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
                            }
                            : {
                                enterFromClass: 'opacity-0 scale-75',
                                enterActiveClass: 'transition-all duration-200 ease-out',
                                leaveActiveClass: 'transition-all duration-200 ease-out',
                                leaveToClass: 'opacity-0 scale-75'
                            }
        }
    },
    divider: {
        root: ({ props }) => ({
            class: [
                'flex relative', // alignments.
                { 'justify-center': props.layout == 'vertical' },
                { 'items-center': props.layout == 'vertical' },
                {
                    'justify-start':
                        (props == null
                            ? void 0
                            : props.align) == 'left' &&
                        props.layout == 'horizontal',
                    'justify-center':
                        (props == null
                            ? void 0
                            : props.align) == 'center' &&
                        props.layout == 'horizontal',
                    'justify-end':
                        (props == null
                            ? void 0
                            : props.align) == 'right' &&
                        props.layout == 'horizontal',
                    'items-center':
                        (props == null
                            ? void 0
                            : props.align) == 'top' &&
                        props.layout == 'vertical',
                    'items-start':
                        (props == null
                            ? void 0
                            : props.align) == 'center' &&
                        props.layout == 'vertical',
                    'items-end':
                        (props == null
                            ? void 0
                            : props.align) == 'bottom' &&
                        props.layout == 'vertical'
                },
                // Spacing
                {
                    'my-5 mx-0 py-0 px-5': props.layout == 'horizontal',
                    'mx-4 md:mx-5 py-5': props.layout == 'vertical'
                },
                // Size
                {
                    'w-full': props.layout == 'horizontal',
                    'min-h-full': props.layout == 'vertical'
                },
                // Before: Line
                'before:block',
                // Position
                {
                    'before:absolute before:left-0 before:top-1/2':
                        props.layout == 'horizontal',
                    'before:absolute before:left-1/2 before:top-0 before:transform before:-translate-x-1/2':
                        props.layout == 'vertical'
                },
                // Size
                {
                    'before:w-full': props.layout == 'horizontal',
                    'before:min-h-full': props.layout == 'vertical'
                },
                // Shape
                {
                    'before:border-solid': props.type == 'solid',
                    'before:border-dotted': props.type == 'dotted',
                    'before:border-dashed': props.type == 'dashed'
                },
                // Color
                {
                    'before:border-t before:border-background':
                        props.layout == 'horizontal',
                    'before:border-l before:border-background':
                        props.layout == 'vertical'
                }
            ]
        }),
        content: 'px-1 bg-background z-10' // Padding and background color.
    },
    image: {
        root: 'relative inline-block',
        button: {
            class: [
                'absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300',
                'bg-transparent text-gray-100',
                'hover:opacity-100 hover:cursor-pointer hover:bg-black hover:bg-opacity-50' //Hover
            ]
        },
        previewMask: {
            class: [
                'flex items-center justify-center',
                'absolute',
                'inset-0 opacity-0 transition-opacity duration-300',
                'bg-transparent text-backgroundOffset3',
                'hover:opacity-100 hover:cursor-pointer hover:bg-black hover:bg-opacity-50'
            ]
        },
        mask: {
            class: [
                'fixed top-0 left-0 w-full h-full',
                'flex items-center justify-center',
                'bg-black bg-opacity-90'
            ]
        },
        toolbar: {
            class: ['absolute top-0 right-0 flex', 'p-4']
        },
        rotateRightButton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnBackground hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        rotateRightIcon: 'w-6 h-6',
        rotateLeftButton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        rotateLeftIcon: 'w-6 h-6',
        zoomOutButton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        zoomOutIcon: 'w-6 h-6',
        zoomInButton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        zoomInIcon: 'w-6 h-6',
        closeButton: {
            class: [
                'flex justify-center items-center',
                'text-textOnSecondary bg-transparent w-12 h-12 rounded-full transition duration-200 ease-in-out mr-2',
                'hover:text-textOnSecondary hover:bg-background/10',
                'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]'
            ]
        },
        closeIcon: 'w-6 h-6',
        transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-all duration-150 ease-in-out',
            leaveActiveClass: 'transition-all duration-150 ease-in',
            leaveToClass: 'opacity-0 scale-75'
        }
    },
    inputtext: {
        root: ({ props, context, parent }) => {
            var _a
            return {
                class: [
                    // InputGroup
                    { 'flex-1 w-[1%]': parent.instance.$name == 'InputGroup' },
                    { 'rounded-lg': parent.instance.$name !== 'InputGroup' },
                    {
                        'first:rounded-l-lg rounded-none last:rounded-r-lg':
                            parent.instance.$name == 'InputGroup'
                    },
                    {
                        'border-0 border-y border-l last:border-r':
                            parent.instance.$name == 'InputGroup'
                    },
                    // Font
                    'font-sans',
                    // Spacing
                    'm-0 w-full',
                    // Size
                    {
                        'text-lg px-4 py-4': props.size == 'large',
                        'px-2 py-2': props.size == 'small',
                        'py-2 px-3': props.size == null
                    },
                    // Colors
                    'text-textOnSecondary bg-secondary',
                    'placeholder:text-backgroundOffset3',
                    'shadow-md',
                    {
                        'border-backgroundOffset3': !props.invalid
                    },
                    // Invalid State
                    'invalid:focus:ring-error',
                    'invalid:hover:border-error',
                    { 'border-error': props.invalid },
                    // States
                    {
                        'hover:ring-1 hover:ring-offset-2 hover:ring-primary focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-2 focus-visible:ring-primary':
                            !context.disabled,
                        'opacity-60 select-none pointer-events-none cursor-default':
                            context.disabled
                    },
                    // Filled State *for FloatLabel
                    {
                        filled:
                            ((_a = parent.instance) == null
                                ? void 0
                                : _a.$name) == 'FloatLabel' && context.filled
                    },
                    // Misc
                    'appearance-none',
                    'transition-colors duration-200'
                ]
            }
        }
    },
    message: {
        root: ({ props }) => ({
            class: [
                'my-2 py-2 rounded-lg drop-shadow-lg',
                {
                    'bg-info border-solid border-0 border-l-4 border-info text-textOnInfo':
                        props.severity == 'info',
                    'bg-success border-solid border-0 border-l-4 border-success text-textOnSuccess':
                        props.severity == 'success',
                    'bg-warning border-solid border-0 border-l-4 border-warning text-textOnWarning':
                        props.severity == 'warn',
                    'bg-error border-solid border-0 border-l-4 border-error text-textOnError':
                        props.severity == 'error'
                }
            ]
        }),
        content: {
            class: ['flex items-center h-full', 'py-3 px-5 gap-2']
        },
        icon: {
            class: ['w-6 h-6', 'text-xl mr-2']
        },
        text: 'font-sans w-full',
        closeButton: {
            class: [
                'w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out',
                'ml-auto overflow-hidden relative',
                'flex items-center justify-center',
                'hover:bg-primary'
            ]
        },
        transition: {
            enterFromClass: 'opacity-0',
            enterActiveClass: 'transition-opacity duration-300',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
            leaveToClass: 'max-h-0 opacity-0 !m-0'
        }
    },
    password: {
        root: ({ props }) => ({
            class: [
                'inline-flex relative w-full',
                {
                    'opacity-60 select-none pointer-events-none cursor-default':
                        props.disabled
                }
            ]
        }),
        maskIcon: {
            class: ['absolute top-1/2 -mt-2', 'right-3 text-textOnSecondary']
        },
        unmaskIcon: {
            class: ['absolute top-1/2 -mt-2', 'right-3 text-textOnSecondary']
        },
        transition: {
            enterFromClass: 'opacity-0 scale-75',
            enterActiveClass: 'transition-transform transition-opacity duration-150 ease-in',
            leaveActiveClass: 'transition-opacity duration-150 ease-linear',
            leaveToClass: 'opacity-0'
        }
    },
    toast: {
        root: ({ props }) => ({
            class: [
                'w-[90%] lg:w-96',
                {
                    'p-toast-center': props.position === 'center',
                    'p-toast-top-left': props.position === 'top-left',
                    'p-toast-top-center': props.position === 'top-center',
                    'p-toast-top-right': props.position === 'top-right',
                    'p-toast-bottom-left': props.position === 'bottom-left',
                    'p-toast-bottom-center': props.position === 'bottom-center',
                    'p-toast-bottom-right': props.position === 'bottom-right'
                }
            ]
        }),
        message: ({ props }) => ({
            class: [
                'my-2 rounded-md w-full',
                {
                    'bg-info border-solid border-0 border-l-4 border-info text-textOnInfo':
                        props.message?.severity == 'info',
                    'bg-success border-solid border-0 border-l-4 border-success text-textOnSuccess':
                        props.message?.severity == 'success',
                    'bg-warning border-solid border-0 border-l-4 border-warning text-textOnWarning':
                        props.message?.severity == 'warn',
                    'bg-error border-solid border-0 border-l-4 border-error text-textOnError':
                        props.message?.severity == 'error'
                }
            ]
        }),
        messageContent: 'flex items-center py-2 px-3',
        messageIcon: {
            class: ['w-6 h-6', 'text-lg mr-2']
        },
        messageText: 'text-sm font-normal flex flex-col flex-1 grow shrink ml-4',
        summary: 'font-semibold block',
        detail: 'mt-1 block',
        closeButton: {
            class: [
                'w-8 h-8 rounded-full bg-transparent transition duration-200 ease-in-out',
                'ml-auto overflow-hidden relative',
                'flex items-center justify-center',
                'hover:bg-white/30'
            ]
        },
        transition: {
            enterFromClass: 'opacity-0 translate-x-0 translate-y-2/4 translate-z-0',
            enterActiveClass: 'transition-transform transition-opacity duration-300',
            leaveFromClass: 'max-h-40',
            leaveActiveClass: 'transition-all duration-500 ease-in',
            leaveToClass: 'max-h-0 opacity-0 mb-0 overflow-hidden'
        }
    },
    toolbar: {
        root: {
            class: ['flex items-center justify-between flex-wrap', 'bg-background px-4 pt-2']
        },
        start: 'flex items-start',
        center: 'flex items-center',
        end: 'flex items-center'
    },
    directives: {
        tooltip: {
            root: ({ context }) => ({
                class: [
                    'absolute shadow-md',
                    {
                        'py-0 px-1':
                            context?.right ||
                            context?.left ||
                            (!context?.right &&
                                !context?.left &&
                                !context?.top &&
                                !context?.bottom),
                        'py-1 px-0':
                            context?.top || context?.bottom
                    }
                ]
            }),
            arrow: ({ context }) => ({
                class: [
                    'absolute w-0 h-0 border-transparent border-solid',
                    {
                        '-m-t-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-gray-600':
                            context?.right ||
                            (!context?.right &&
                                !context?.left &&
                                !context?.top &&
                                !context?.bottom),
                        '-m-t-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-gray-600':
                            context?.left,
                        '-m-l-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-gray-600':
                            context?.top,
                        '-m-l-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-gray-600':
                            context?.bottom
                    }
                ]
            }),
            text: {
                class: 'p-3 bg-gray-600 text-white text-xs rounded-md whitespace-pre-line break-words'
            }
        },
    }
}

export const primeVueOptions = {
    csp: {
        nonce: 'k7tw3fyODk0qbjmYmd'
    },
    inputStyle: 'filled',
    unstyled: true,
    pt: MentorNetDesign
}